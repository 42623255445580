import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface LandingProps {}

const Landing: FC<LandingProps> = () => {
  const [searchParams] = useSearchParams();
  const redirectUrl: string = searchParams.get("urlredirect") || "";

  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (redirectUrl) openPage();
  }, [redirectUrl]);

  /**
   * Detects the user's mobile OS.
   * @returns The mobile operating system ('ios', 'android', or 'unknown').
   */
  const getMobileOS = (): "ios" | "android" | "unknown" => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    if (/android/i.test(userAgent)) {
      return "android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "ios";
    }
    return "unknown";
  };

  /**
   * Open page from query parameter or redirect to store if app is not installed.
   * @param e Optional click event.
   */
  const openPage = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault();

    const validScheme: string = "myhyundaiindonesia://";

    if (redirectUrl.startsWith(validScheme) && !redirectUrl.includes("javascript:")) {
      const startTime = Date.now();

      // Attempt to open the app
      window.location.href = redirectUrl;

      // Check if the app opened successfully
      setTimeout(() => {
        const elapsed = Date.now() - startTime;
        if (elapsed < 2000) {
          // Redirect to store if app didn't open
          const os = getMobileOS();
          if (os === "android") {
            window.location.href = "https://play.google.com/store/apps/details?id=id.co.hyundaimotor.mmp";
          } else if (os === "ios") {
            window.location.href = "https://apps.apple.com/id/app/myhyundai-indonesia/id1580588669?l=id"; 
          } else {
            setMessage("Failed to redirect. Please install the app manually.");
          }
        }
      }, 1500);
    } else {
      setMessage("Failed to redirect. Invalid or missing URL.");
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-background p-4">
      <div className="flex flex-col justify-center items-center mb-4">
        <h1 className="text-lg text-center text-black hyundaiTextHead mb-2">
          Redirecting to myHyundai app...
        </h1>
        <p className="text-sm text-center">
          If nothing happened,{" "}
          <a
            href="#"
            className="cursor-pointer text-secondary"
            onClick={openPage}
          >
            click here
          </a>{" "}
          to open the app manually.
        </p>
      </div>
      {message && <p className="text-sm text-center text-danger">{message}</p>}
    </div>
  );
};

export default Landing;
